// @flow

import React from 'react';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';

// Assets
import solvayLogo from 'assets/solvay-logo-original.png';
import solvayLogoMobile from 'assets/solvay-logo-mobile.png';

// Components
import {
    GearIcon,
    PrimaryButton,
    ProfileIcon,
    SubtleAnchor,
    TabNav,
    LegacyTheme,
    ToolTip,
    ToolTipTrigger,
} from 'OsedeaReactUI';

// Constants
import {
    REQUEST_SUPPORT_LINK,
    NAVIGATION_ITEMS_COLLECTORS,
    NAVIGATION_ITEMS_FROTHERS,
    NAVIGATION_ALLOWED_ROUTES,
    PROJECT_TYPE,
    ROUTES,
} from 'utils/constants';

// Styles
import {
    AccountToolTipContent,
    HelpCTA,
    HelpToolTip,
    HelpToolTipContent,
    LogoContainer,
    Logo,
    PrimaryWrapper,
    ProjectIdentifier,
    ProjectToolTipContent,
    SecondaryNav,
    SecondaryWrapper,
    SubtleHeader,
    Wrapper,
} from './styles';

// Types
import type { IntlType } from 'types';
import type { UserType } from 'management/services/Authentication/types';
import type { ProjectType } from 'services/Project/types';

type Props = {
    history: {
        push: (string) => void,
        go: (number) => void,
    },
    intl: IntlType,
    location: {
        pathname: string,
    },
    project?: ProjectType,
    onLogout: () => void,
    user?: UserType,
    userIsFetching: boolean,
    onClearSingleProject: () => void,
};

class Header extends React.PureComponent<Props, null> {
    static defaultProps = {
        project: null,
        user: null,
    };

    createRedirectHandler = (path?: string) => () => this.props.history.push(path || '/');

    /**
     * Redirects the user to '/' if not already on '/'.
     */
    handleRefreshPage = () => {
        if (this.props.location.pathname === '/') {
            return;
        }

        this.props.history.push('/');
        this.props.onClearSingleProject();
    };

    provideCurrentRoute = () => this.props.location.pathname.split('/')[3];

    renderPrimaryNavContent = () => {
        const currentPathname = this.provideCurrentRoute();
        const { project } = this.props;
        // Default value for the navigation tab, no items by default (Usually during the project loading time)
        let navigationItems = [];
        if (project) {
            switch (this.props.project.get('projectType')) {
                case PROJECT_TYPE.COLLECTOR:
                    navigationItems = NAVIGATION_ITEMS_COLLECTORS;
                    break;
                case PROJECT_TYPE.FROTHER:
                    navigationItems = NAVIGATION_ITEMS_FROTHERS;
                    break;
            }
        }

        if (
            this.props.user &&
            NAVIGATION_ALLOWED_ROUTES &&
            NAVIGATION_ALLOWED_ROUTES.indexOf(currentPathname) !== -1
        ) {
            return <TabNav current={currentPathname} maxWidth={1048} options={navigationItems} />;
        } else {
            return null;
        }
    };

    renderEditDisclaimersLink = () => {
        let editDisclaimersLinkRoute;
        let editDisclaimersLinkIntlId;

        if (this.props.location.pathname.split('/')[2] === 'disclaimers') {
            editDisclaimersLinkIntlId = 'components.Header.backToHome';
        } else {
            editDisclaimersLinkIntlId = 'components.Header.editDisclaimers';
            editDisclaimersLinkRoute = ROUTES.DISCLAIMERS;
        }

        return (
            <li>
                <SubtleAnchor
                    onClick={this.createRedirectHandler(editDisclaimersLinkRoute)}
                    noUnderline
                >
                    {this.props.intl.formatMessage({
                        id: editDisclaimersLinkIntlId,
                    })}
                </SubtleAnchor>
            </li>
        );
    };

    renderUserManagementLink = () => {
        let userManagementLinkRoute;
        let userManagementLinkIntlId;

        if (this.props.location.pathname.split('/')[2] === 'users') {
            userManagementLinkIntlId = 'components.Header.backToHome';
        } else {
            userManagementLinkIntlId = 'components.Header.userManagement';
            userManagementLinkRoute = ROUTES.USERS;
        }

        return (
            <li>
                <SubtleAnchor
                    onClick={this.createRedirectHandler(userManagementLinkRoute)}
                    noUnderline
                >
                    {this.props.intl.formatMessage({
                        id: userManagementLinkIntlId,
                    })}
                </SubtleAnchor>
            </li>
        );
    };

    renderApplicationSettingsToolTip = () => {
        if (
            !this.props.user ||
            (this.props.user && !this.props.user.get('isAdmin')) ||
            this.props.userIsFetching
        ) {
            return null;
        }

        return (
            <ToolTip
                content={
                    <AccountToolTipContent>
                        {this.renderUserManagementLink()}
                        {this.renderEditDisclaimersLink()}
                    </AccountToolTipContent>
                }
                position="bottom"
                trigger={
                    <div style={{ color: LegacyTheme.defaultIconColor }}>
                        <GearIcon />
                    </div>
                }
                triggerType="click"
                closeOnInternalClick
                interactive
            />
        );
    };

    renderProfilIconToolTip = () => {
        if (!this.props.user || this.props.userIsFetching) {
            return null;
        }

        return (
            <ToolTip
                content={
                    <AccountToolTipContent>
                        <li>
                            <SubtleAnchor id="logout" onClick={this.props.onLogout} noUnderline>
                                {this.props.intl.formatMessage({
                                    id: 'components.Header.logout',
                                })}
                            </SubtleAnchor>
                        </li>
                    </AccountToolTipContent>
                }
                position="bottom"
                trigger={
                    <ProfileIcon
                        defaultImage={solvayLogoMobile}
                        id="profileIcon"
                        profileImage={this.props.user && this.props.user.get('photoUrl')}
                        cursor="true"
                    />
                }
                triggerType="click"
                closeOnInternalClick
                interactive
            />
        );
    };

    renderProjectIndicator = () => {
        const currentPathname = this.provideCurrentRoute();

        if (
            this.props.project &&
            NAVIGATION_ALLOWED_ROUTES &&
            NAVIGATION_ALLOWED_ROUTES.indexOf(currentPathname) !== -1
        ) {
            return (
                <ToolTip
                    content={
                        <ProjectToolTipContent>
                            <li>
                                <SubtleHeader>
                                    {this.props.intl.formatMessage({
                                        id: 'components.Header.projectOwner',
                                    })}
                                </SubtleHeader>
                                {this.props.project.get('owner')}
                            </li>
                            <li>
                                <SubtleHeader>
                                    {this.props.intl.formatMessage({
                                        id: 'components.Header.plant',
                                    })}
                                </SubtleHeader>
                                {this.props.project.get('plant')}
                            </li>
                            <li>
                                <SubtleHeader>
                                    {this.props.intl.formatMessage({
                                        id: 'components.Header.projectObjectives',
                                    })}
                                </SubtleHeader>
                                {this.props.project.get('objectives')}
                            </li>
                            <li>
                                <SubtleHeader>
                                    {this.props.intl.formatMessage({
                                        id: 'components.Header.projectType',
                                    })}
                                </SubtleHeader>
                                {this.props.project.get('projectType')}
                            </li>
                            <li>
                                <PrimaryButton
                                    onClick={this.createRedirectHandler('/')}
                                    text={this.props.intl.formatMessage({
                                        id: 'components.Header.switchProjects',
                                    })}
                                />
                            </li>
                        </ProjectToolTipContent>
                    }
                    position="bottom"
                    trigger={
                        <ProjectIdentifier>
                            {this.props.intl.formatMessage(
                                { id: 'components.Header.projectId' },
                                { number: this.props.project.get('id') }
                            )}
                        </ProjectIdentifier>
                    }
                    triggerType="click"
                    interactive
                />
            );
        } else {
            return null;
        }
    };

    render() {
        return (
            <Wrapper>
                <SecondaryWrapper>
                    <LogoContainer>
                        <Logo
                            onClick={this.handleRefreshPage}
                            isHomePage={this.props.location.pathname === '/'}
                        >
                            <img src={solvayLogo} alt="Solvay - Select A Guide" />
                        </Logo>
                    </LogoContainer>
                </SecondaryWrapper>
                <PrimaryWrapper>{this.renderPrimaryNavContent()}</PrimaryWrapper>
                <SecondaryWrapper>
                    <SecondaryNav>
                        {this.renderProjectIndicator()}
                        {this.renderApplicationSettingsToolTip()}
                        {this.renderProfilIconToolTip()}
                        {REQUEST_SUPPORT_LINK && (
                            <div>
                                <HelpToolTip>
                                    <ToolTip
                                        content={
                                            <HelpToolTipContent>
                                                <p>
                                                    {this.props.intl.formatMessage({
                                                        id: 'components.Header.helpTitle',
                                                    })}
                                                </p>
                                                <HelpCTA>
                                                    <a
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        href={`${REQUEST_SUPPORT_LINK}`}
                                                    >
                                                        {this.props.intl.formatMessage({
                                                            id: 'components.Header.requestSupport',
                                                        })}
                                                    </a>
                                                </HelpCTA>
                                            </HelpToolTipContent>
                                        }
                                        position="bottom"
                                        trigger={
                                            <React.Fragment>
                                                <ToolTipTrigger
                                                    size={16}
                                                    title={this.props.intl.formatMessage({
                                                        id: 'components.Header.helpLink',
                                                    })}
                                                />
                                            </React.Fragment>
                                        }
                                        triggerType="click"
                                        interactive
                                    />
                                </HelpToolTip>
                            </div>
                        )}
                    </SecondaryNav>
                </SecondaryWrapper>
            </Wrapper>
        );
    }
}

export default withRouter(injectIntl(Header));
