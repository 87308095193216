// @flow

/*
 * env.js
 * These are app environment constants such as where our backend is located, what
 * version of the API we are using, etc
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'YOUR_ACTION_CONSTANT';
 */

const apiUrls = {
    development: 'http://0.0.0.0:8000',
    dev: 'https://api-dot-gcp-sqo-select-a-guide-d.uk.r.appspot.com',
    staging: 'https://api-dot-gcp-sqo-select-a-guide-t.uk.r.appspot.com',
    production: 'https://api-dot-gcp-sqo-select-a-guide-p.uk.r.appspot.com',
};

const devConfig = {
    apiKey: 'AIzaSyDrCZexSM9vbTj58J1j14thLGISpgqpRwM',
    authDomain: 'gcp-sqo-select-a-guide-d.firebaseapp.com',
    databaseURL: 'https://gcp-sqo-select-a-guide-d.firebaseio.com',
    projectId: 'gcp-sqo-select-a-guide-d',
};

const prodConfig = {
    apiKey: 'AIzaSyCl3BvpP9rTG0LIv6TfrbhmX2JXk1rRPG0',
    authDomain: 'gcp-sqo-select-a-guide-p.firebaseapp.com',
    databaseURL: 'https://gcp-sqo-select-a-guide-p.firebaseio.com',
    projectId: 'gcp-sqo-select-a-guide-p',
};

export const API_URL = process.env.NODE_ENV ? apiUrls[process.env.NODE_ENV] : 'http://0.0.0.0:8000';
export const API_VER = '/api/';
// Local DEV and staging environments will refer to firebase development environment, production environment will be the sole to use live firebase environment
export const firebaseConfig = process.env.NODE_ENV === 'production' ? prodConfig : devConfig;

export const gaTrackingId = '';
